























import Vue from 'vue';
import CreateSubscriptionContractRequestViewModel from '@/src/services/viewModel/resource/CreateSubscriptionContractRequestViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
  }),
  computed: {
    subscriptionDisplay(): string {
      const [subscription] = this.innerValue.orderBasket?.items ?? [];
      return subscription?.articleDisplay!;
    },
    innerValue: {
      get(): CreateSubscriptionContractRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateSubscriptionContractRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
});
